import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  ACCESS_TOKEN,
  USER,
  NEW_USER,
  IMPERSONATE,
} from "constants/ActionTypes";
import axiosJSON from "../../util/Api";
import { messageError } from "../../util/AlertMessage";

export const userSignIn = (
  { employeeId, password },
  fcm_token
  // latitude,
  // longitude
) => {
  const body = {
    employee_id: employeeId,
    password: password,
    fcm_token: fcm_token,
    // latitude: latitude,
    // longitude: longitude,
  };
  return (dispatch) => {
    // dispatch({ type: FETCH_START });

    // const cookies_string = `${employeeId}_cookies`;
    // if (localStorage.getItem(cookies_string)) {
    //   axiosJSON.defaults.headers.common["X-Id-Token"] =
    //     localStorage.getItem(cookies_string);
    // }
    // localStorage.setItem(`${employeeId}_latitute`, latitude);
    // localStorage.setItem(`${employeeId}_longitude`, longitude);

    axiosJSON
      .post("/api/auth/login/", body)
      .then(({ data, status }) => {
        if (status === 202) {
          localStorage.setItem("current_employee_id", employeeId);
          localStorage.setItem("token", data.data.token);
          // localStorage.setItem(`${cookies_string}`, data.cookie);
          axiosJSON.defaults.headers["authorization"] =
            "Token " + data.data.token;
          // axiosJSON.defaults.headers.common["X-Id-Token"] = data.cookie;

          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER, payload: data.data });
          dispatch({ type: ACCESS_TOKEN, payload: data.data.token });
        } else {
          // dispatch({ type: FETCH_ERROR, payload: data.message });
          messageError(data.message);
        }
      })
      .catch(function (error) {
        messageError(error.message ? error.message : error);
      });
  };
};

export const myDetails = (token) => {
  // const employeeId = localStorage.getItem("current_employee_id");
  // const cookies_string = `${employeeId}_cookies`;
  // axiosJSON.defaults.headers.common["X-Id-Token"] = localStorage.getItem(`${cookies_string}`)
  axiosJSON.defaults.headers["authorization"] = "Token " + token;
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axiosJSON
      .get("/api/employee/me/")
      .then(({ data, status }) => {
        if (status === 200) {
          localStorage.setItem("team", JSON.stringify(data.data.team));
          if (localStorage.getItem("version") !== data.data.version) {
            localStorage.setItem("version", data.data.version);
            window.location.reload(true);
          }
          // console.log("team -->", localStorage.getItem("team"));
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER, payload: data.data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
      });
  };
};

export const impersonatedMeDetails = (token) => {
  axiosJSON.defaults.headers["authorization"] = "Token " + token;
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axiosJSON
      .get("/api/employee/me/")
      .then(({ data, status }) => {
        if (status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          let current_token = localStorage.getItem("current_token");
          if (current_token) {
            dispatch({ type: NEW_USER, payload: data.data });
            dispatch({ type: IMPERSONATE, payload: true });
          }
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
export const stopImpersonate = () => {
  localStorage.removeItem("current_token");
  localStorage.removeItem("current_id");
  return (dispatch) => {
    dispatch({ type: NEW_USER, payload: "" });
    dispatch({ type: IMPERSONATE, payload: false });
    window.location.reload();
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axiosJSON
      .get("/api/employee/logout/")
      .then(({ data, status }) => {
        if (status === 204) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SIGNOUT_USER });
          localStorage.clear();
          window.location.reload();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error });
      });
  };
};

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser,
  };
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
