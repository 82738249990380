import React, { useCallback, useEffect, useState } from "react";
import { Button, Layout, Modal, message } from "antd";
import TopbarHeader from "../Topbar/HorizontalNav";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";
import Topbar from "../Topbar/index";
import NotifySupervisor from "../../modals/NotifySupervisor";
// import {footerText} from "util/config";
import App from "routes/index";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { useRouteMatch } from "react-router-dom";
import {
  myDetails,
  // unreadNotify,
  impersonatedMeDetails,
  userSignOut,
} from "../../appRedux/actions";
import firebase from "firebase";
import axiosJSON, { LAMBDA_URL } from "../../util/Api";
import EditProfile from "../../modals/editProfile";
import {
  messageError,
  messageSuccess,
  messageWarning,
} from "../../util/AlertMessage";
import NotifyEnginnerFeedback from "../../modals/NotifyEnginnerFeedback";
import NotifyEngineerUpdate from "../../modals/NotifyEngineerUpdate";
import Axios from "axios";
import { dumbData } from "../../util/trackingAPI";

const {
  Content,
  // Footer
} = Layout;

const MainApp = (props) => {
  const { width, navStyle } = useSelector(({ settings }) => settings);
  const dispatch = useDispatch();
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [pendingFeedback, setPendingFeedback] = useState({});
  const match = useRouteMatch();
  const messaging = firebase.messaging();
  const currentUser = props.currentAuth ? props.currentAuth : props.auth;
  localStorage.setItem("user", JSON.stringify(currentUser));

  messaging.onMessage = (payload) => {
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon,
    };

    if (!("Notification" in window)) {
      console.log("This browser does not support system notifications.");
    } else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      var notification = new Notification(
        notificationTitle,
        notificationOptions
      );
      notification.onclick = function (event) {
        event.preventDefault();
        // window.open(payload.notification.click_action , '_blank');
        notification.close();
      };
    }
  };

  useEffect(
    () => {
      const putCordinate = async (latitude, longitude, employeeId) => {
        try {
          let user = localStorage.getItem("user");
          if (user && user !== undefined) {
            user = JSON.parse(user);
            let body = {
              Latitude: latitude,
              Longitude: longitude,
              Team: user.team.name,
              Department: user.team.department,
              EmpID: user.employee_id,
              EmpName: user.employee_name,
              OS: localStorage.getItem("OS"),
            };
            if (!localStorage.getItem("OS")) delete body["OS"];

            await Axios.post(`${LAMBDA_URL}/api/activity`, body);
            localStorage.setItem(`${employeeId}_latitude`, latitude);
            localStorage.setItem(`${employeeId}_longitude`, longitude);
          }
        } catch (error) {
          console.log(error);
        }
      };
      const successCallback = (position) => {
        var timer = null;
        const latitude = String(position.coords.latitude);
        const longitude = String(position.coords.longitude);
        let employeeId = localStorage.getItem("current_employee_id");
        const localLatitude = localStorage.getItem(`${employeeId}_latitude`);
        const localLongitude = localStorage.getItem(`${employeeId}_longitude`);
        if (
          employeeId &&
          localLatitude &&
          localLatitude !== undefined &&
          localLongitude &&
          localLongitude !== undefined
        ) {
          if (
            !localLatitude.startsWith(latitude.substring(0, 5)) ||
            !localLongitude.startsWith(longitude.substring(0, 5))
          ) {
            localStorage.setItem(`${employeeId}_latitude`, latitude);
            localStorage.setItem(`${employeeId}_longitude`, longitude);
            // need to call API from here
            timer = setTimeout(() => {
              putCordinate(latitude, longitude, employeeId);
              if (timer) clearTimeout(timer);
            }, 1000);
          }
        } else {
          timer = setTimeout(() => {
            putCordinate(latitude, longitude, employeeId);
            if (timer) clearTimeout(timer);
          }, 1000);
        }
        if (localStorage.getItem("null_latitude"))
          localStorage.removeItem("null_latitude");
        if (localStorage.getItem("null_longitude"))
          localStorage.removeItem("null_longitude");
        if (localStorage.getItem("undefined_latitude"))
          localStorage.removeItem("undefined_latitude");
        if (localStorage.getItem("undefined_longitude"))
          localStorage.removeItem("undefined_longitude");
      };

      const errorCallback = (error) => {
        console.log(error);
        if (error.message === "The permission was revoked") {
          return message.error(
            "The permission has been revoked from your end. Kindly open 'log1' in a new tab."
          );
        }
        // message.error("Please enable location to use log1");
        // dispatch(userSignOut());
      };

      // const logInterval = setInterval(() => {
      //   navigator.geolocation.getCurrentPosition(
      //     successCallback,
      //     errorCallback
      //   );
      // }, 1000);

      const interval = setInterval(() => {
        navigator.permissions.query({ name: "geolocation" }).then((result) => {
          // setPermissionStatus(result.state); // "granted", "denied", or "prompt"
          if (result.state === "prompt" || result.state === "denied") {
            message.error("Please enable location to use log1");
            dispatch(userSignOut());
          }
        });
      }, 10000); // Runs every 60 seconds

      navigator.geolocation.watchPosition(successCallback, errorCallback, {
        enableHighAccuracy: false, // Faster but less accurate
        timeout: 120000, // Stop trying if no result in 1 min
        maximumAge: 300000, // Use cached location for up to 2 min
      });

      const apiInterval = setInterval(() => {
        // need to call API from here as well
        let employeeId = localStorage.getItem("current_employee_id");
        const localLatitude = localStorage.getItem(`${employeeId}_latitude`);
        const localLongitude = localStorage.getItem(`${employeeId}_longitude`);
        putCordinate(localLatitude, localLongitude);
      }, 120000);

      const dumbInterval = setInterval(() => {
        const activityList = localStorage.getItem("activityList");
        if (activityList && activityList.length > 0) {
          dumbData(JSON.parse(activityList));
        }
      }, 300000);

      // navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      localStorage.setItem("OS", window.navigator.platform);

      return () => {
        clearInterval(interval);
        clearInterval(apiInterval);
        clearInterval(dumbInterval);
      };
    },
    // eslint-disable-next-line
    []
  );

  // useEffect(() => {
  //   let locationCheckTimer = null;
  //   let apiCallTimer = null;

  //   const putCordinate = async (latitude, longitude, employeeId) => {
  //     try {
  //       let user = JSON.parse(localStorage.getItem("user") || "{}");

  //       if (!user || !user.employee_id) return;

  //       let body = {
  //         Latitude: latitude,
  //         Longitude: longitude,
  //         Team: user.team?.name,
  //         Department: user.team?.department,
  //         EmpID: user.employee_id,
  //         EmpName: user.employee_name,
  //         OS: localStorage.getItem("OS"),
  //       };

  //       if (!body.OS) delete body.OS;

  //       await Axios.post(`${LAMBDA_URL}/api/activity`, body);
  //       localStorage.setItem(`${employeeId}_latitude`, latitude);
  //       localStorage.setItem(`${employeeId}_longitude`, longitude);
  //     } catch (error) {
  //       console.error("Error updating coordinates:", error);
  //     }
  //   };

  //   const successCallback = (position) => {
  //     const latitude = String(position.coords.latitude);
  //     const longitude = String(position.coords.longitude);
  //     let employeeId = localStorage.getItem("current_employee_id");

  //     if (!employeeId) return;

  //     const localLatitude = localStorage.getItem(`${employeeId}_latitude`);
  //     const localLongitude = localStorage.getItem(`${employeeId}_longitude`);

  //     // Check if the location has changed significantly before making API call
  //     if (
  //       !localLatitude ||
  //       !localLongitude ||
  //       !localLatitude.startsWith(latitude.substring(0, 5)) ||
  //       !localLongitude.startsWith(longitude.substring(0, 5))
  //     ) {
  //       if (apiCallTimer) clearTimeout(apiCallTimer);

  //       apiCallTimer = setTimeout(() => {
  //         putCordinate(latitude, longitude, employeeId);
  //       }, 1000);
  //     }

  //     // Clean up invalid location data
  //     [
  //       "null_latitude",
  //       "null_longitude",
  //       "undefined_latitude",
  //       "undefined_longitude",
  //     ].forEach((key) => {
  //       if (localStorage.getItem(key)) localStorage.removeItem(key);
  //     });
  //   };

  //   const errorCallback = (error) => {
  //     if (error.message === "The permission was revoked") {
  //       message.error(
  //         "Location permission has been revoked. Kindly enable it."
  //       );
  //     } else {
  //       message.error(
  //         "Please enable location to continue using the application."
  //       );
  //       dispatch(userSignOut());
  //     }
  //   };

  //   // Request location once immediately
  //   navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
  //     enableHighAccuracy: false,
  //   });

  //   // Periodically check for location changes (every 1 minute)
  //   locationCheckTimer = setInterval(() => {
  //     navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
  //       enableHighAccuracy: false,
  //     });
  //   }, 60000);

  //   // Periodic API sync every 2 minutes
  //   const apiSyncTimer = setInterval(() => {
  //     let employeeId = localStorage.getItem("current_employee_id");
  //     const localLatitude = localStorage.getItem(`${employeeId}_latitude`);
  //     const localLongitude = localStorage.getItem(`${employeeId}_longitude`);
  //     if (localLatitude && localLongitude) {
  //       putCordinate(localLatitude, localLongitude, employeeId);
  //     }
  //   }, 120000);

  //   // Background sync for offline data every 5 minutes
  //   const offlineSyncTimer = setInterval(() => {
  //     const activityList = localStorage.getItem("activityList");
  //     if (activityList) {
  //       dumbData(JSON.parse(activityList));
  //     }
  //   }, 300000);

  //   // Store OS information once
  //   if (!localStorage.getItem("OS")) {
  //     localStorage.setItem("OS", window.navigator.platform);
  //   }

  //   // Cleanup intervals on unmount
  //   return () => {
  //     clearInterval(locationCheckTimer);
  //     clearInterval(apiSyncTimer);
  //     clearInterval(offlineSyncTimer);
  //     if (apiCallTimer) clearTimeout(apiCallTimer);
  //   };
  // }, [dispatch]); // Add dependencies if necessary

  useEffect(() => {
    if (Notification.permission === "granted") {
      messaging.getToken().then((t) => {
        updateFCMToken(t);
      });
    } else if (Notification.permission === "default") {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            return messaging.getToken();
          } else return null;
        })
        .then((token) => {
          updateFCMToken(token);
        })
        .catch((error) => {
          if (error.code === "messaging/permission-blocked") {
            messageWarning(
              "You have blocked the permission for push notifications"
            );
          }
        });
    } else {
      messageWarning("You have blocked the permission for push notifications");
    }
    let currentToken = localStorage.getItem("current_token");
    if (currentToken) {
      if (profileCheck(props.currentAuth)) {
        setIsEditProfile(true);
      }
    } else {
      if (profileCheck(props.auth)) {
        setIsEditProfile(true);
      }
    }
  }, [messaging, props.auth, props.currentAuth]);

  const profileCheck = (user) => {
    if (user) {
      if (user.shift) {
        if (
          user.roles.indexOf("engineer") > -1 ||
          user.roles.indexOf("delivery") > -1
        ) {
          if (user.technology && user.technology.length > 0) {
            return false;
          } else {
            return true;
          }
        }
        return false;
      }
      return true;
    }
  };

  const updateFCMToken = (token) => {
    const body = {
      fcm_token: token,
    };
    axiosJSON
      .post("/api/fcm/", body)
      .then(({ data, status }) => {
        messageSuccess("Your system can receive notifications now!");
      })
      .catch(function (e) {
        console.log("Error:", e.error);
      });
  };

  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return "";
    }
  };

  // const getUnreadCount = (token) => {
  //   dispatch(unreadNotify(token));
  // };
  const getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_FIXED:
        return <Topbar authInfo={currentUser} />;
      case NAV_STYLE_DRAWER:
        return <Topbar authInfo={currentUser} />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar authInfo={currentUser} />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      default:
        return null;
    }
  };
  const getTopbar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <TopbarHeader />;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <TopbarHeader />;
      case NAV_STYLE_DRAWER:
        return <TopbarHeader />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <TopbarHeader />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <TopbarHeader />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <TopbarHeader />;
      default:
        return null;
    }
  };
  const getPendingFeedbackData = useCallback(() => {
    const id = currentUser.id;
    if (id) {
      axiosJSON
        .get(`/api/emp_notify/` + id + `/notification_due/`)
        .then(({ status, data }) => {
          if (status === 200) {
            setPendingFeedback(data.data);
          }
        })
        .catch((err) => messageError(err));
    }
  }, [currentUser]);
  // const getSidebar = (navStyle, width) => {
  //   if (width < TAB_SIZE) {
  //     return <TopbarHeader/>;
  //   }
  //   switch (navStyle) {
  //     case NAV_STYLE_FIXED :
  //       return <Sidebar/>;
  //     case NAV_STYLE_DRAWER :
  //       return <Sidebar/>;
  //     case NAV_STYLE_MINI_SIDEBAR :
  //       return <Sidebar/>;
  //     case NAV_STYLE_NO_HEADER_MINI_SIDEBAR :
  //       return <Sidebar/>;
  //     case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
  //       return <Sidebar/>;
  //     default :
  //       return null;
  //   }
  // };

  useEffect(() => {
    let token = localStorage.getItem("token");
    let currentToken = localStorage.getItem("current_token");
    if (currentToken) {
      dispatch(impersonatedMeDetails(currentToken));
      // getUnreadCount(currentToken);
      if (profileCheck(props.currentAuth)) {
        setIsEditProfile(true);
      }
    } else {
      // getUnreadCount(token);
      if (profileCheck(props.auth)) {
        setIsEditProfile(true);
      }
    }
    dispatch(myDetails(token));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getPendingFeedbackData();
    const broadcast = new BroadcastChannel("feedback-due-channel");
    broadcast.onmessage = (event) => {
      if (event.data.action === "SHOW_FEEDBACK") {
        getPendingFeedbackData();
      }
    };
  }, [currentUser, getPendingFeedbackData]);

  const onProfileButtonClick = () => {
    setIsEditProfile(false);
    setEditProfile(true);
  };
  // const getProfile = () => {
  //   let currentToken = localStorage.getItem("current_token");
  //   if(currentToken)
  //     return props.currentAuth
  //   else
  //     return props.auth
  // }
  const onRevrt = (link) => {
    setEditProfile(false);
    window.location.href = link;
  };

  const handleClose = () => {
    setEditProfile(false);
  };

  return (
    <Layout className="gx-app-layout">
      <Layout>
        {getNavStyles(navStyle)}
        {getTopbar(navStyle, width)}
        <Content
          className={`gx-layout-content ${getContainerClass(navStyle)} `}
        >
          <App match={match} />
          {/*<Footer style={{marginTop: '0px'}}>*/}
          {/*  <div className="gx-layout-footer-content" style={{textAlign: 'center'}}>*/}
          {/*    {footerText}*/}
          {/*  </div>*/}
          {/*</Footer>*/}
          {isEditProfile && (
            <Modal
              title="Update Profile"
              visible={isEditProfile}
              footer={null}
              closable={false}
            >
              <div>
                <div className="my-3">
                  <span>
                    {
                      "Please add your respective details in Log1 by going to Edit Profile section"
                    }
                  </span>
                </div>
                <div className="d-flex" style={{ justifyContent: "flex-end" }}>
                  <Button type="default" onClick={onProfileButtonClick}>
                    Visit Profile
                  </Button>
                </div>
              </div>
            </Modal>
          )}
          {pendingFeedback?.interview?.interviews.length > 0 && (
            <Modal
              title="Add Supervisor Feedback"
              visible={pendingFeedback?.interview?.interviews.length > 0}
              closable={false}
              footer={null}
              maskClosable={false}
              width={"80%"}
              onCancel={() => {
                setPendingFeedback((preState) => ({
                  ...preState,
                  interview: { interviews: [] },
                }));
              }}
              keyboard={false}
            >
              <NotifySupervisor
                feedbacks={pendingFeedback.interview.interviews}
                userId={currentUser.id}
                getPendingFeedbackData={getPendingFeedbackData}
                count={pendingFeedback.interview.count}
              />
            </Modal>
          )}
          {pendingFeedback?.project?.projects.length > 0 && (
            <Modal
              title="Add Project Feedback"
              visible={pendingFeedback?.project?.projects.length > 0}
              closable={false}
              footer={null}
              width={"80%"}
              maskClosable={false}
              onCancel={() => {
                setPendingFeedback((preState) => ({
                  ...preState,
                  project: { projects: [] },
                }));
              }}
              keyboard={false}
            >
              <NotifyEnginnerFeedback
                feedbacks={pendingFeedback.project.projects}
                userId={currentUser.id}
                getPendingFeedbackData={getPendingFeedbackData}
                count={pendingFeedback.project.count}
              />
            </Modal>
          )}
          {pendingFeedback?.update?.updates.length > 0 && (
            <Modal
              title="Add Project Updates"
              visible={pendingFeedback?.update?.updates.length > 0}
              closable={false}
              footer={null}
              maskClosable={false}
              width={"80%"}
              onCancel={() => {
                setPendingFeedback((preState) => ({
                  ...preState,
                  update: { updates: [] },
                }));
              }}
              keyboard={false}
            >
              <NotifyEngineerUpdate
                feedbacks={pendingFeedback.update.updates}
                userId={currentUser.id}
                getPendingFeedbackData={getPendingFeedbackData}
                count={pendingFeedback.update.count}
              />
            </Modal>
          )}
          {editProfile && (
            <Modal
              title="Profile"
              onCancel={() => setEditProfile(false)}
              visible={editProfile}
              footer={null}
              className="edit_model"
            >
              <EditProfile handleClose={handleClose} onRevert={onRevrt} />
            </Modal>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.authUser,
  currentAuth: state.auth.newUser,
});

export default connect(mapStateToProps)(MainApp);
