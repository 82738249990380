import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import { connect } from "react-redux";
import NotFound from "../util/NotFound";
import { checkExcludeRoles, checkRoles } from "../util/Rolescheck";

const App = ({ match, auth, currentAuth }) => {
  const { roles } = currentAuth ? currentAuth : auth;

  const routes = [
    {
      path: `${match.url}engineering`,
      component: asyncComponent(() => import("./Engineering")),
      access: checkRoles(roles, ["engineer", "superadmin"], "or"),
    },
    {
      path: `${match.url}engineering_module`,
      component: asyncComponent(() => import("./EngineeingModule")),
      access: checkRoles(roles, ["engineer", "superadmin"], "or"),
    },
    {
      path: `${match.url}engineering_report`,
      component: asyncComponent(() => import("./EngineeringData/EngineerList")),
      access: checkRoles(roles, ["engineer", "superadmin"], "or"),
    },
    {
      path: `${match.url}project/`,
      component: asyncComponent(() => import("./EngineeringDetailedView")),
      access: checkRoles(roles, ["engineer", "superadmin"], "or"),
    },
    {
      path: `${match.url}reportDetail`,
      component: asyncComponent(() =>
        import("./Engineering/ReportDetails/index")
      ),
      access: checkRoles(roles, ["engineer", "superadmin"], "or"),
    },
    {
      path: `${match.url}engineering_team`,
      component: asyncComponent(() => import("./EngineeringTeam")),
      access:
        checkRoles(roles, ["engineer"], "all") &&
        checkRoles(roles, ["superadmin", "admin", "scrum_master"], "or"),
    },
    {
      path: `${match.url}eng/compare_team`,
      component: asyncComponent(() => import("./EngineeringTeam/CompareTeam")),
      access:
        checkRoles(roles, ["engineer"], "all") &&
        checkRoles(roles, ["superadmin", "admin", "scrum_master"], "or"),
    },
    {
      path: `${match.url}team_detail`,
      component: asyncComponent(() => import("./EngineeringTeam/TeamDetail")),
      access:
        checkRoles(roles, ["engineer"], "all") &&
        checkRoles(roles, ["superadmin", "admin", "scrum_master"], "or"),
    },

    // {
    //   path: `${match.url}finance/leave_management`,
    //   component: asyncComponent(() => import("./FinanceList")),
    //   access: checkRoles(roles, ["superadmin", "finance"], "or"),
    // },
    // {
    //   path: `${match.url}finance/details/`,
    //   component: asyncComponent(() => import("./FinanceDetailView")),
    //   access: checkRoles(roles, ["superadmin", "finance"], "or"),
    // },
    // {
    //   path: `${match.url}finance/leave_details/:id/:projectId`,
    //   component: asyncComponent(() =>
    //     import("./FinanceDetailView/LeaveDetails")
    //   ),
    //   access: checkRoles(roles, ["superadmin", "finance"], "or"),
    // },
    // {
    //   path: `${match.url}finance/timesheet_history/:id/:projectId`,
    //   component: asyncComponent(() =>
    //     import("./FinanceDetailView/TimesheetRequests")
    //   ),
    //   access: checkRoles(roles, ["superadmin", "finance"], "or"),
    // },
    // {
    //   path: `${match.url}finance/timesheet_details/:id/:projectId`,
    //   component: asyncComponent(() =>
    //     import("./FinanceDetailView/TimesheetsDetails")
    //   ),
    //   access: checkRoles(roles, ["superadmin", "finance"], "or"),
    // },
    // {
    //   path: `${match.url}timesheet`,
    //   component: asyncComponent(() => import("./Finance/Timesheet")),
    //   access: checkRoles(roles, ["superadmin", "finance"], "or"),
    // },
    // {
    //   path: `${match.url}finance/leave/`,
    //   component: asyncComponent(() =>
    //     import("./FinanceDetailView/LeaveBalance")
    //   ),
    //   access: checkRoles(roles, ["superadmin", "finance"], "or"),
    // },
    // {
    //   exact: true,
    //   path: `${match.url}finance`,
    //   component: asyncComponent(() => import("./Finance")),
    //   access: checkRoles(roles, ["superadmin", "finance"], "or"),
    // },
    {
      path: `${match.url}finance/rate_revision`,
      component: asyncComponent(() => import("./RateRevision")),
      access: checkRoles(roles, ["superadmin", "finance", "retention"], "or"),
    },
    {
      path: `${match.url}legal`,
      component: asyncComponent(() => import("./Legal")),
      access: checkRoles(roles, ["superadmin", "legal"], "or"),
    },
    {
      path: `${match.url}petition`,
      component: asyncComponent(() => import("./Legal/Petition")),
      access: checkRoles(roles, ["superadmin", "legal"], "or"),
    },
    {
      path: `${match.url}marketing_detail`,
      component: asyncComponent(() =>
        import("./Marketing/MarketingTeam/TeamsDetail")
      ),
      access:
        checkRoles(roles, ["marketer"], "all") &&
        checkRoles(roles, ["superadmin", "admin", "scrum_master"], "or"),
    },
    {
      path: `${match.url}compare_teams`,
      component: asyncComponent(() =>
        import("./Marketing/MarketingTeam/CompareTeams")
      ),
      access:
        checkRoles(roles, ["marketer"], "all") &&
        checkRoles(roles, ["superadmin", "admin", "scrum_master"], "or"),
    },
    {
      path: `${match.url}finance/leave-management`,
      component: asyncComponent(() =>
        import("./FinanceModule/LeaveManagement/index")
      ),
      access: checkRoles(roles, ["superadmin", "finance"], "or"),
    },
    {
      path: `${match.url}finance/leave-details/:id`,
      component: asyncComponent(() =>
        import("./FinanceModule/LeaveManagement/leaveDetails")
      ),
      access: checkRoles(roles, ["superadmin", "finance"], "or"),
    },
    {
      path: `${match.url}finance/timesheet/request/:projectId/:consultantId`,
      component: asyncComponent(() =>
        import("./FinanceModule/TimesheetNew/TimesheetRequest")
      ),
      access: checkRoles(roles, ["superadmin", "finance"], "or"),
    },
    {
      path: `${match.url}finance/timesheet/:projectId/:consultantId`,
      component: asyncComponent(() =>
        import("./FinanceModule/TimesheetNew/TimesheetDetail")
      ),
      access: checkRoles(roles, ["superadmin", "finance"], "or"),
    },
    {
      path: `${match.url}finance/timesheet`,
      component: asyncComponent(() =>
        import("./FinanceModule/TimesheetNew/Timesheet")
      ),
      access: checkRoles(roles, ["superadmin", "finance"], "or"),
    },
    {
      path: `${match.url}finance/paystub/:projectId/:consultantId`,
      component: asyncComponent(() =>
        import("./FinanceModule/PayStub/payStubDetails")
      ),
      access: checkRoles(roles, ["superadmin", "finance"], "or"),
    },
    {
      path: `${match.url}finance/paystub`,
      component: asyncComponent(() => import("./FinanceModule/PayStub/index")),
      access: checkRoles(roles, ["superadmin", "finance"], "or"),
    },
    {
      path: `${match.url}utility`,
      component: asyncComponent(() => import("./Utility")),
      access: checkExcludeRoles(roles, ["usa_employee", "attio_user"], "or"),
    },
    {
      path: `${match.url}consultant`,
      component: asyncComponent(() => import("./Consultant")),
      access: checkExcludeRoles(roles, ["usa_employee", "attio_user"], "or"),
    },
  ];

  return (
    <div className="gx-main-content-wrapper main_wrapper">
      <Switch>
        <Route
          path={`${match.url}dashboard`}
          component={asyncComponent(() => import("./Dashboard"))}
        />
        <Route
          path={`${match.url}notification`}
          component={asyncComponent(() => import("./Notification"))}
        />
        <Route
          path={`${match.url}marketing`}
          component={asyncComponent(() => import("./Marketing"))}
        />

        <Route
          path={`${match.url}consultant_detail_report/:type/:id`}
          component={asyncComponent(() =>
            import("./Marketing/RedirectReport/consultantRedirectReport")
          )}
        />
        {routes.map(({ path, component, exact, access }, index) => (
          <Route
            path={path}
            component={access ? component : NotFound}
            exact={exact ? true : false}
            key={index}
          />
        ))}
        <Route
          path={`${match.url}myprofile`}
          component={asyncComponent(() => import("./MyProfile"))}
        />
        <Route
          path={`${match.url}new`}
          component={asyncComponent(() => import("./CreateLeadForm"))}
        />
        <Route
          path={`${match.url}details`}
          component={asyncComponent(() => import("./ViewSubmission"))}
        />
        <Route
          path={`${match.url}addConsultant`}
          component={asyncComponent(() => import("./AddConsultant"))}
        />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.authUser,
  currentAuth: state.auth.newUser,
});

export default connect(mapStateToProps)(App);
